import * as React from "react";
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Seo from "../components/seo"

const ThankYouPage = (props) => {
    let title = "Thank You! ✅ | Pyxel Development"
    let location = props.location.pathname
    return (
        <div>
            <Layout>
                <Seo title={title} location={location} />
                <div className="bg-offblue py-80">
                    <div className="container mx-auto px-5">
                        <h1 className="py-3 uppercase font-pixeboy text-6xl md:text-8xl text-center -mt-48 text-white">
                            Thank You!
                        </h1>
                        <p className="text-lg md:text-2xl text-center max-w-3xl mx-auto">We value your interest and time and hope that we can continue to build a long-lasting and prosperous relationship with you.
                            We take pride in our 1 day replies and are looking forward to working with you!
                        </p>
                        <div className="flex justify-center max-w-xs mx-auto py-10">
                            <StaticImage src="../images/success.png" placeholder="none" title="Customer Success" alt="Customer Success" />
                        </div>
                        <div>
                            <h2 className="text-4xl md:text-5xl text-center font-pixeboy text-white">FOLLOW US</h2>
                            <div className="flex justify-center space-x-4">
                                <a className="text-white hover:text-gray-500" href="https://www.facebook.com/pyxeldevelopment/" target="blank" rel="noreferrer" title="Visit Pyxel Development on Facebook" alt="Visit Pyxel Development on Facebook">
                                    <svg className="h-8 w-8" fill="currentColor" viewBox="0 0 24 24"><path fillRule="evenodd"
                                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343
                                        21.128 22 16.991 22 12z" clipRule="evenodd"></path>
                                    </svg>
                                </a>
                                <a href="https://www.instagram.com/pyxeldevelopment/" target="_blank" rel="noreferrer" title="Visit Pyxel Development on Instagram" alt="Visit Pyxel Development on Instagram" className="text-white hover:text-gray-500">
                                    <svg className="h-8 w-8" fill="currentColor" viewBox="0 0 24 24">
                                        <path fillRule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 
                                        4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 
                                        01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 
                                        2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 
                                        2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0
                                        00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                                            clipRule="evenodd"></path>
                                    </svg>
                                </a>
                                <a href="https://www.google.com/maps/place/Pyxel+Development/@38.5780737,-121.5037324,16.97z/data=!4m5!3m4!1s0x809ad120ae47ee33:0x72ef1aa21e61d95b!8m2!3d38.5780883!4d-121.5014984?hl=en" target="_blank" rel="noreferrer" title="Visit Pyxel Development on Google" alt="Visit Pyxel Development on Google" className="text-white hover:text-gray-500">
                                    <svg class="h-8 w-8" fill="currentColor" viewBox="0 0 640 512"><path fill="currentColor" d="M386.061 228.496c1.834 9.692 3.143 19.384 3.143 31.956C389.204 370.205 315.599 448 204.8 448c-106.084 0-192-85.915-192-192s85.916-192 192-192c51.864 0 95.083 18.859 128.611 50.292l-52.126 
                                    50.03c-14.145-13.621-39.028-29.599-76.485-29.599-65.484 0-118.92 54.221-118.92 121.277 0 67.056 53.436 121.277 118.92 121.277 75.961 0 104.513-54.745 108.965-82.773H204.8v-66.009h181.261zm185.406 6.437V179.2h-56.001v55.733h-55.733v56.001h55.733v55.733h56.001v-55.733H627.2v-56.001h-55.733z"></path>
                                    </svg>
                                </a>
                            </div>
                        </div>
                        <div className="flex justify-center md:-mt-16 -mb-72">
                            <Link to="/" title="Go to Pyxel Development Home Page" alt="Go to Pyxel Development Home Page">
                                <button className="m-5 relative h-10 px-6 text-xs text-black transition-colors bg-transparent rounded-lg border border-black focus:shadow-outline hover:bg-white md:mt-20">
                                    <span>Return Home</span>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </Layout >
        </div >
    )
}
export default ThankYouPage
